import * as React from 'react'
import { GatsbySSR } from 'gatsby'
import Hotjar from './src/tracking/Hotjar'

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => {
  return (
    <>
      {element}
      <Hotjar />
    </>
  )
}
