exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aangifte-check-adviesrapport-[year]-[token]-tsx": () => import("./../../../src/pages/aangifte-check/adviesrapport/[year]/[token].tsx" /* webpackChunkName: "component---src-pages-aangifte-check-adviesrapport-[year]-[token]-tsx" */),
  "component---src-pages-aangifte-check-betaling-onsuccesvol-tsx": () => import("./../../../src/pages/aangifte-check/betaling-onsuccesvol.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-betaling-onsuccesvol-tsx" */),
  "component---src-pages-aangifte-check-betaling-succesvol-tsx": () => import("./../../../src/pages/aangifte-check/betaling-succesvol.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-betaling-succesvol-tsx" */),
  "component---src-pages-aangifte-check-download-aangifte-tsx": () => import("./../../../src/pages/aangifte-check/download-aangifte.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-download-aangifte-tsx" */),
  "component---src-pages-aangifte-check-index-tsx": () => import("./../../../src/pages/aangifte-check/index.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-index-tsx" */),
  "component---src-pages-aangifte-check-uitslag-tsx": () => import("./../../../src/pages/aangifte-check/uitslag.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-uitslag-tsx" */),
  "component---src-pages-aangifte-check-upload-tsx": () => import("./../../../src/pages/aangifte-check/upload.tsx" /* webpackChunkName: "component---src-pages-aangifte-check-upload-tsx" */),
  "component---src-pages-cookie-instellingen-tsx": () => import("./../../../src/pages/cookie-instellingen.tsx" /* webpackChunkName: "component---src-pages-cookie-instellingen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

