module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/aangifte-facivon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13a6394ce6b71e4b1c0d88f167c557c6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"captureUncaught":true,"captureUnhandledRejections":true,"payload":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-55C842B","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"()=>({originalLocation:document.location.protocol+\"//\"+document.location.hostname+document.location.pathname+document.location.search})"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
