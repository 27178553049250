import * as React from 'react'
import type { GatsbySSR } from 'gatsby'
import { ThemeProvider } from '@emotion/react'
import { theme } from './src/theme'
import { StateMachineProvider } from 'little-state-machine'
export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <StateMachineProvider>{element}</StateMachineProvider>
    </ThemeProvider>
  )
}
