import { Theme } from '@emotion/react'

const bodyFont = 'Roboto'
const headingFont = 'Montserrat'

const heightBreakpointValues = {
  xs: 414,
  sm: 544,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1370,
}

const breakpointValues = {
  xs: 414,
  sm: 544,
  md: 768,
  lg: 1078,
  xl: 1200,
  xxl: 1370,
}

const maxWidthValues = {
  xs: 0,
  sm: 606,
  md: 750,
  lg: 970,
  xl: 1170,
  xxl: 1660,
}

export const theme: Theme = {
  colors: {
    A500: '#6E2CFF',
    A700: '#3E16B7',
    A800: '#2A0E93',
    A900: '#1D087A',
    G500: '#00664C',
    G400: '#008765',
    G300: '#24C27F',
    G200: '#36B389',
    G100: '#79F2CA',
    G75: '#ABF5D6',
    G50: '#E3FCEF',
    B500: '#0741A6',
    B400: '#004ECC',
    B300: '#297AFF',
    B200: '#2684FF',
    B100: '#4C91FF',
    B75: '#B3D0FF',
    B50: '#DEEBFF',
    B25: '#F0F6FF',
    R500: '#BF2600',
    R400: '#DE350B',
    R300: '#FF5630',
    R200: '#FF7452',
    R100: '#FF8F73',
    R75: '#FFBDAD',
    R50: '#FFEBE6',
    Y500: '#FF8B00',
    Y400: '#FF991F',
    Y300: '#FFAB00',
    Y200: '#FFC400',
    Y100: '#FFE380',
    Y75: '#FFF0B3',
    Y50: '#FFFAE6',
    N900: '#091E42',
    N800: '#172B4D',
    N700: '#253858',
    N600: '#344563',
    N500: '#42526E',
    N400: '#505F79',
    N300: '#5E6C84',
    N200: '#6B778C',
    N100: '#7A869A',
    N90: '#8993A4',
    N80: '#97A0AF',
    N70: '#A5ADBA',
    N60: '#B3BAC5',
    N50: '#C1C7D0',
    N40: '#DFE1E6',
    N30: '#EBECF0',
    N20: '#F4F5F7',
    N10: '#FAFBFC',
    N0: '#FFFFFF',
  },
  spacing: {
    padding: {
      small: 10,
      medium: 20,
      large: 30,
    },
    borderRadius: {
      default: 4,
    },
  },
  typography: {
    type: {
      primary: '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',
      secondary: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    weight: {
      regular: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
    },
    size: {
      s1: '12',
      s2: '14',
      s3: '16',
      m1: '20',
      m2: '24',
      m3: '28',
      l1: '35',
      l2: '40',
      l3: '48',
    },
  },
  fonts: {
    body: bodyFont,
    heading: headingFont,
    pxToRem: (value: number) => `${value / 16}rem`,
  },
  fontSizes: {
    desktop: {
      h1: 26,
      h2: 18,
      h3: 18,
      body: 16,
      small: 14,
    },
    mobile: {
      h1: 24,
      h2: 18,
      h3: 24,
      body: 16,
      small: 14,
    },
  },
  fontWeights: {
    thin: 300,
    body: 400,
    heading: 700,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.375,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    border: [0, 4, 8, 16, 32],
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
  },
  breakpointSizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: Object.values(breakpointValues).map(item => `${item}px`),
  maxWidths: Object.values(maxWidthValues),
  breakpoint: {
    up: (key: keyof typeof breakpointValues) => `@media (min-width:${breakpointValues[key]}px)`,
    down: (key: keyof typeof breakpointValues) => `@media (max-width:${breakpointValues[key] - 5 / 100}px)`,
    between: (start: keyof typeof breakpointValues, end: keyof typeof breakpointValues) =>
      `@media (min-width:${breakpointValues[start]}px) and (max-width:${breakpointValues[end] - 5 / 100}px)`,
    only: (key: keyof typeof breakpointValues) => {
      const keys = Object.keys(breakpointValues) as (keyof typeof breakpointValues)[]
      if (keys.indexOf(key) + 1 < keys.length) {
        return `@media (min-width:${breakpointValues[key]}px) and (max-width:${
          breakpointValues[keys[keys.indexOf(key) + 1]] - 5 / 100
        }px)`
      }

      return `@media (min-width:${breakpointValues[key]}px)`
    },
  },
  heightBreakpoint: {
    up: (key: keyof typeof heightBreakpointValues) => `@media (min-height:${heightBreakpointValues[key]}px)`,
    down: (key: keyof typeof heightBreakpointValues) =>
      `@media (max-height:${heightBreakpointValues[key] - 5 / 100}px)`,
  }
}
