/**
 * Implement Gatsby's Node APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { createStore } from 'little-state-machine'

createStore(
  {},
  {
    name: 'aangifte_check',
  }
)

export * from './gatsby-wrap-root-element'
export * from './gatsby-wrap-page-element'
